export default {
  error404: {
    number: '404',
    title: 'Oops, not found!',
    text: 'Sorry, the IDW you were looking for is no longer here'
  },
  loading: {
    title: 'Loading IDW',
    text: 'Promptly retrieving your IDW from the cloud'
  },
  logo: {
    alt: 'OneReach.ai logo'
  },
  platformBex: {
    title: 'In the browser extension',
    description: 'Intelligent Digital Workers browser extension enables IDWs with push notifications and voice capabilities. Additionally, it allows you to see the IDWs you’ve added, manage them and browse their notification and call history.',
    getBrowserExtension: 'Get browser extension',
    addToBrowserExtension: 'Add to browser extension',
    openWithBrowserExtension: 'Open with browser extension'
  },
  platformMacos: {
    title: 'In the desktop app',
    description: 'Intelligent Digital Workers desktop application enables IDWs with push notifications capability. Additionally, it allows you to see the IDWs you\'ve added, manage them and browse their notification history.',
    getApps: 'Get Intelligent Digital Workers',
    addToApps: 'Add to IDWs'
  },
  platformMobile: {
    title: 'On your smartphone',
    description: 'Intelligent Digital Workers mobile application enable IDWs with push notifications and voice capabilities. Additionally, it allows you to see the IDWs you’ve added, manage them and browse their notification and call history.\nScan this QR code with your smartphone to add this IDW on your smartphone.',
    qrCode: 'QR code'
  },
  platformWeb: {
    title: 'In the new tab',
    description: 'When run in a browser IDWs do not currently support push notifications. Consider choosing any of the other available options if you\'d like this IDW to be able to leverage these capabilities. We\'re working hard to implement support for these features in browsers.',
    openApp: 'Open IDW'
  },
  platformWindows: {
    title: 'In the desktop app',
    description: 'Intelligent Digital Workers desktop application enables IDWs with push notifications capability. Additionally, it allows you to see the IDWs you\'ve added, manage them and browse their notification history.',
    getApps: 'Get Intelligent Digital Workers',
    addToApps: 'Add to IDWs',
    notInstalledYet: 'No app installed?'
  },
  shareDesktop: {
    chooseHowToRun: 'Choose how you want to run this IDW:',
    whatIsTheDifference: 'What is the difference?',
    description: 'As of right now not all of the capabilities are supported across all of the platforms. Specific details are listed against each of the options above. We\'re working hard to achieve parity in this regard.'
  },
  shareMobile: {
    description: 'Intelligent Digital Workers mobile application enable IDWs with push notifications and voice capabilities. Additionally, it allows you to see the IDWs you\'ve added, manage them and browse their notification and call history.',
    addToMyApps: 'Add to my IDWs',
    openInBrowser: 'Open in Browser',
    noAppInstalled: 'No app installed?',
    downloadApp: 'Download Intelligent Digital Workers',
    step1: '1. Download Intelligent Digital Workers app',
    step2: '2. Add your bot once app is installed'
  }
}
