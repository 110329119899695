
import { defineComponent, PropType, inject } from 'vue'
import AppInfo from '@/components/AppInfo.vue'
import { IApp } from '@/interfaces'
import { GlobalStore as GlobalStoreKey } from '@/symbolKeys'
import { makeOpenAppUrl, canOpenAppUsingUrl } from '@/apps/app-url'

export default defineComponent({
  name: 'ShareMobile',
  components: {
    AppInfo
  },
  setup () {
    const GlobalStore = inject(GlobalStoreKey, {} as Record<string, boolean>)
    return {
      GlobalStore
    }
  },
  props: {
    appData: {
      type: Object as PropType<IApp>,
      required: true
    }
  },
  data () {
    return {
      currentStep: 1
    }
  },
  methods: {
    copyShareUrlToClipboard () {
      navigator.clipboard.writeText(this.appData.shareUrl)
    }
  },
  computed: {
    canOpenApp (): boolean {
      return canOpenAppUsingUrl(this.appData)
    },
    openAppUrl (): string | undefined {
      return makeOpenAppUrl(this.appData)
    }
  }
})
