
import { defineComponent, inject, PropType } from 'vue'
import { IApp } from '@/interfaces'
import { GlobalStore as GlobalStoreKey } from '@/symbolKeys'

const IS_APP_INSTALLED_DATA_ATTR_NAME = 'isAppInstalled'
const BEX_ADD_APP_DATA_ATTR_NAME = 'addAppToBex'

export default defineComponent({
  name: 'PlatfromBex',
  setup () {
    const GlobalStore = inject(GlobalStoreKey, {} as Record<string, boolean>)
    return { GlobalStore }
  },
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    appData: {
      type: Object as PropType<IApp>,
      required: true
    }
  },
  data () {
    return {
      isBexInstalled: false,
      isAppInstalled: false
    }
  },
  methods: {
    onGetBexClick () {
      document.documentElement.dataset[BEX_ADD_APP_DATA_ATTR_NAME] = this.appData.id
    },
    init () {
      this.isBexInstalled = document.documentElement.dataset[IS_APP_INSTALLED_DATA_ATTR_NAME] !== undefined
      this.isAppInstalled = document.documentElement.dataset[IS_APP_INSTALLED_DATA_ATTR_NAME] === 'true'
    }
  },
  mounted () {
    setTimeout(this.init, 50)
  }
})
