
import { IApp } from '@/interfaces'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'PlatformMobile',
  props: {
    appData: {
      type: Object as PropType<IApp>,
      required: true
    },
    isActive: {
      type: Boolean,
      default: false
    }
  }
})
