
import { makeOpenAppUrl } from '@/apps/app-url'
import { IApp } from '@/interfaces'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'PlatformWeb',
  props: {
    appData: {
      type: Object as PropType<IApp>,
      required: true
    },
    isActive: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    openAppUrl (): string | undefined {
      return makeOpenAppUrl(this.appData)
    }
  }
})
