import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Logo = _resolveComponent("Logo")
  const _component_Loading = _resolveComponent("Loading")
  const _component_Error404 = _resolveComponent("Error404")
  const _component_ShareMobile = _resolveComponent("ShareMobile")
  const _component_ShareDesktop = _resolveComponent("ShareDesktop")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_Logo),
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_Loading, { key: 0 }))
      : (_ctx.isError404)
        ? (_openBlock(), _createBlock(_component_Error404, { key: 1 }))
        : (_ctx.GlobalStore.isMobile)
          ? (_openBlock(), _createBlock(_component_ShareMobile, {
              key: 2,
              appData: _ctx.appData
            }, null, 8, ["appData"]))
          : (_openBlock(), _createBlock(_component_ShareDesktop, {
              key: 3,
              appData: _ctx.appData
            }, null, 8, ["appData"]))
  ], 64))
}