
import { PropType, defineComponent, inject, ref } from 'vue'
import { GlobalStore as GlobalStoreKey } from '@/symbolKeys'
import { IApp } from '@/interfaces'

export default defineComponent({
  name: 'PlatformWindows',
  setup () {
    const GlobalStore = inject(GlobalStoreKey, {} as Record<string, boolean>)

    return { GlobalStore, showInstall: ref(false) }
  },
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    appData: {
      type: Object as PropType<IApp>,
      required: true
    }
  },
  methods: {
    addToApps () {
      this.showInstall = true
      this.copyAppUrl()
    },
    copyAppUrl () {
      const temp = document.createElement('textarea')
      temp.value = this.appData.fallbackLink
      document.body.appendChild(temp)
      temp.select()
      temp.setSelectionRange(0, 99999)
      document.execCommand('copy')
      document.body.removeChild(temp)
    }
  }

})
