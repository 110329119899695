
import { defineComponent, PropType, inject } from 'vue'
import AppInfo from '@/components/AppInfo.vue'
import PlatformBex from '@/components/PlatformBex.vue'
import PlatformMacos from '@/components/PlatformMacos.vue'
import PlatformWindows from '@/components/PlatformWindows.vue'
import PlatformMobile from '@/components/PlatformMobile.vue'
import PlatformWeb from '@/components/PlatformWeb.vue'
import { IApp, PLATFORM_TYPE, IPlatform } from '@/interfaces'
import { GlobalStore as GlobalStoreKey } from '@/symbolKeys'
import { canOpenAppUsingUrl } from '@/apps/app-url'

export default defineComponent({
  name: 'ShareDesktop',
  components: {
    AppInfo,
    PlatformBex,
    PlatformMacos,
    PlatformWindows,
    PlatformMobile,
    PlatformWeb
  },
  props: {
    appData: {
      type: Object as PropType<IApp>,
      required: true
    }
  },
  setup () {
    const GlobalStore = inject(GlobalStoreKey, {} as Record<string, boolean>)
    return {
      GlobalStore
    }
  },
  data () {
    return {
      platforms: [] as IPlatform[]
    }
  },
  computed: {
    activePlatform (): IPlatform {
      return this.platforms.find((platform: IPlatform) => platform.isActive) as IPlatform
    },
    showWhatIsTheDifferenceSection (): boolean {
      const displayedPlatformsList = this.platforms.filter((platform: IPlatform) => {
        if (platform.componentName === PLATFORM_TYPE.BEX) {
          return this.GlobalStore.isChromeExtensionCompatibleBrowser
        }
        return true
      })
      return displayedPlatformsList.length > 1
    }
  },
  methods: {
    onPlatformClick (platform: IPlatform): void {
      if (this.activePlatform === platform) {
        return
      }
      this.activePlatform.isActive = false
      platform.isActive = true
    }
  },
  created () {
    this.platforms = [
      // { componentName: PLATFORM_TYPE.BEX, isActive: this.GlobalStore.isChromeExtensionCompatibleBrowser },
      { componentName: PLATFORM_TYPE.MOBILE, isActive: false }
    ]
    if (canOpenAppUsingUrl(this.appData)) {
      this.platforms.push({ componentName: PLATFORM_TYPE.WEB, isActive: false })
    }
    if (this.GlobalStore.isWindowsOs) {
      this.platforms.unshift({ componentName: PLATFORM_TYPE.WINDOWS, isActive: false })
    }
    this.platforms[0].isActive = true
  }
})
