
import * as env from '@/../deploy/config/env.json'
import axios from 'axios'
import { defineComponent, inject } from 'vue'
import Logo from '@/components/Logo.vue'
import Loading from '@/views/Loading.vue'
import Error404 from '@/views/Error404.vue'
import ShareDesktop from '@/views/ShareDesktop.vue'
import ShareMobile from '@/views/ShareMobile.vue'
import { IApp } from '@/interfaces'
import { DARK_MODE_MEDIA_RULE, MOBILE_MODE_MEDIA_RULE } from '@/consts'
import { GlobalStore as GlobalStoreKey } from '@/symbolKeys'

export default defineComponent({
  name: 'App',
  components: {
    Logo,
    Loading,
    Error404,
    ShareDesktop,
    ShareMobile
  },
  setup () {
    const GlobalStore = inject(GlobalStoreKey, {} as Record<string, boolean>)
    return { GlobalStore }
  },
  data () {
    return {
      appData: {} as IApp,
      isLoading: true,
      isError404: false
    }
  },
  created () {
    // todo add features support and get rid of the `feature.` removal
    const FEATURE_REGEX = /feature\./i
    const APPS_UI_URL = env.APPS_UI_URL.replace(/"/g, '').replace(FEATURE_REGEX, '')
    const APPS_UI_HOSTNAME = (new URL(APPS_UI_URL)).hostname
    const SDK_API_URL = env.SDK_API_URL.replace(/"/g, '').replace(FEATURE_REGEX, '')
    const PROVIDERS_ACCOUNT_ID = env.PROVIDERS_ACCOUNT_ID.replace(/"/g, '')
    const ENV_URL = `${SDK_API_URL}/http/${PROVIDERS_ACCOUNT_ID}/2042/app`
    const APP_ID = `${document.location.pathname.substr(1)}.${APPS_UI_HOSTNAME}`
    const show404 = () => {
      this.isError404 = true
      this.isLoading = false
      document.title = 'App Not Found'
    }

    if (APP_ID) {
      document.title = 'Loading App...'
      axios.get(`${ENV_URL}?id=${APP_ID}`)
        .then(({ data }: { data: IApp }) => {
          if (data.isActive !== false) {
            this.appData = data
            this.appData.fallbackLink = `onereach://app/${APPS_UI_HOSTNAME}/${data.id}`
            this.$watch('GlobalStore', () => {
              this.appData.qrcode = `${ENV_URL}/qr?id=${APP_ID}${this.GlobalStore.isDarkMode ? '' : '&darkColor=000000'}`
            }, { immediate: true, deep: true })

            document.title = this.appData.name
            this.isLoading = false
          } else {
            show404()
          }
        })
        .catch(() => {
          show404()
        })
    } else {
      show404()
    }
  },
  methods: {
    onDarkModeChanged (ev: MediaQueryListEvent): void {
      this.GlobalStore.isDarkMode = ev.matches
    },
    onMobileModeChanged (ev: MediaQueryListEvent): void {
      this.GlobalStore.isMobile = ev.matches
    }
  },
  mounted () {
    window.matchMedia(DARK_MODE_MEDIA_RULE).addEventListener('change', (event) => {
      this.onDarkModeChanged(event)
    })
    window.matchMedia(MOBILE_MODE_MEDIA_RULE).addEventListener('change', (event) => {
      this.onMobileModeChanged(event)
    })
  },
  unmounted () {
    window.matchMedia(DARK_MODE_MEDIA_RULE).removeEventListener('change', (event) => {
      this.onDarkModeChanged(event)
    })
    window.matchMedia(MOBILE_MODE_MEDIA_RULE).removeEventListener('change', (event) => {
      this.onMobileModeChanged(event)
    })
  }
})
